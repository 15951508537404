import React from "react";

import {Typography} from "../../../typography";

import visa from '../../../../assets/paywall/visa_card.svg';
import mastercard from '../../../../assets/paywall/mastercard_card.svg';
import amex from '../../../../assets/paywall/amex_card.svg';
import discover from '../../../../assets/paywall/discover_card.svg';
import diners from '../../../../assets/paywall/diners_card.svg';

import {useStyles} from "./styles";
import {TypographyVariants} from "../../../../constants";

type CardHeaderProps = {
    headerTitle: string,
    titleClass?: string
}

export const CardHeader = ({ headerTitle, titleClass = '' }: CardHeaderProps) => {
    const { cardList, header, title } = useStyles();

    return (
        <div className={header}>
            <Typography className={`${title} ${titleClass}`} variant={TypographyVariants.title}>
                { headerTitle }
            </Typography>
            <div className={cardList}>
                <img src={visa} alt='' />
                <img src={mastercard} alt='' />
                <img src={amex} alt='' />
                <img src={diners} alt='' />
                <img src={discover} alt='' />
            </div>
        </div>
    )
}