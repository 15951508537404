export const base = {
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '0.0008em',
    fontWeight: 400,
    color: '#121620',
    fontFamily: "'SF Pro Text', system-ui, sans-serif",
    paddingBottom: '4px',
    borderRadius: '10px',
    border: '1px solid #c7c7c7 !important',
    padding: '14px 12px',
    width: '100%',
    height: '100%'
}

export const valid ={
    color: '#121620',
}

export const placeholder = {
    base: {
        color: '#8E8E93',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: "'SF Pro Text', system-ui, sans-serif",
    },
}

export const focus = {
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '0.0008em',
    fontWeight: 400,
    color: '#121620',
    fontFamily: "'SF Pro Text', system-ui, sans-serif",
    paddingBottom: '4px',
    borderRadius: '10px',
    border: '1px solid #c7c7c7 !important',
    padding: '14px 12px',
    width: '100%',
    height: '100%'
}

export const invalid = {
    color: '#FF5758',
    border: '1px solid #FF5758'
}