// node modules
import React, { FC } from 'react';

// local components
import { Typography } from "../../typography";

// local files
import { palette } from "../../../theme/palette";
import { theming } from "../../../theme";
import { useStyles } from "./styles";
import {TypographyVariants} from "../../../constants";
import leftArrow from "../../../assets/back_button.svg";

export interface ProgressBarThinProps {
    percents: number;
    title: string;
    color?: string;
    completed?: boolean;
    className?: string;
    backButton?: boolean;
    handleBack?: () => void;
    hideTitle?: boolean;
}

export const ProgressBarThin: FC<ProgressBarThinProps>  = ({
    percents = 0,
    color = palette.primary,
    completed = true,
    title,
    className,
    backButton = true,
    handleBack,
    hideTitle = false
}) => {
    const {
        progressWrapper,
        progress,
        infoWrapper, counter, backBtn
    } = useStyles({ theme: theming.useTheme() });
    const width = `calc(100% * ${percents/100})`;
    const variant = completed ? TypographyVariants.body2 : TypographyVariants.h3;

    return (
        <div className={className}>
            {!hideTitle && <div className={infoWrapper}>
                <Typography variant={variant} style={{ color }}>{title}</Typography>
                <Typography className={counter} variant={variant} style={{ color }}>{percents} %</Typography>
            </div>}
            <div className={progressWrapper}>
                <div
                    className={progress}
                    style={{
                        background: color,
                        width
                    }}
                />
            </div>
            {backButton && (
                <img
                    className={backBtn}
                    onClick={handleBack}
                    src={leftArrow}
                    alt=""
                />
            )}
        </div>
    )
}
