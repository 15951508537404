export const scriptLoader = (cb: (bool: boolean) => any) => {
    const client = document.createElement('script');

    client.src = 'https://js.braintreegateway.com/web/3.81.0/js/client.min.js';

    client.onload = () => {
        const paypal = document.createElement('script');

        paypal.src = 'https://js.braintreegateway.com/web/3.81.0/js/paypal-checkout.min.js';

        paypal.onload = () => cb(true);

        paypal.onerror = (e: any) => console.error(e);

        document.head.appendChild(paypal);
    }

    client.onerror = (e: any) => console.error(e);

    document.head.appendChild(client);
}
