import { useEffect } from "react";

import { DEFAULT_MULTI_CLICK_PROTECTOR_DELAY } from "../../utils/constants";

let timer: any = null;

export const useMultiClickProtector = (delay: number = DEFAULT_MULTI_CLICK_PROTECTOR_DELAY) => {

    useEffect(() => () => {
        clearTimeout(timer);

        timer = null;
    }, []);

    const handleMultiClickProtector = (cb: (props?: any) => any) => () => {
        if (timer) return;

        timer = setTimeout(() =>{

            timer = null

        }, delay);

        cb();
    }

    return { handleMultiClickProtector }
}
