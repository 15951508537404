// node modules
import { createUseStyles } from "react-jss";

interface PropsInterface {
    height: number,
    component: boolean,
}

export const useStyles = createUseStyles({
    root: {
       height: ({height, component}: PropsInterface) => component ? `${height}px` : `calc(${height}px - 201px)`,
       overflow: 'hidden',
       padding: ({component}: PropsInterface) => component ? '0 32px 0 32px' : '201px 32px 0 32px',
       display: ({component}: PropsInterface) => component ? 'flex' : '',
       alignItems: ({component}: PropsInterface) => component ? 'center' : '',
       justifyContent: "center",
    },
    '@media (min-width: 1024px)' : {
        root: {
            justifyContent: "center",
            display: 'flex',
        },
    }
});
