// node modules
import { createUseStyles } from "react-jss";

// local files
import { alignment } from "../../../theme/alignment";
import { Theme } from "../../../theme";

export const useStyles = createUseStyles({
    progressWrapper: {
        position: 'relative',
        height: 8,
        borderRadius: '1.875rem',
        background: ({ theme: { palette } }: Theme) => palette.gray
    },
    progress: {
        maxWidth: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        borderRadius: '1.875rem',
        transition: 'width .3s'
    },
    counter: {
        minWidth: '50px',
        textAlign: 'right',
    },
    infoWrapper: {
        marginBottom: 8,
        ...alignment.betweenCenter
    },
    backBtn: {
        marginTop: 14
    },
});