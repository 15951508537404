export function evaluateExpressionTree(tree: any): any {
  // #TODO here we able extend or logic for transpile string logical expression to real one
  /* tslint:disable triple-equals*/
  if (tree.type == 'Literal') {
    return tree.value;
    /* tslint:disable triple-equals*/
  } else if (tree.type == 'LogicalExpression') {
    /* tslint:disable triple-equals*/
    if (tree.operator == '&&') {
      return evaluateExpressionTree(tree.left) && evaluateExpressionTree(tree.right);
    } else {
      return evaluateExpressionTree(tree.left) || evaluateExpressionTree(tree.right);
    }
    /* tslint:disable triple-equals*/
  } else if (tree.type == 'BinaryExpression') {
    /* tslint:disable triple-equals*/
    if (tree.operator == '==') {
      /* tslint:disable triple-equals*/
      return evaluateExpressionTree(tree.left) == evaluateExpressionTree(tree.right);
    }
  } else {
    // Unexpected node parsed
    return null;
  }
}
