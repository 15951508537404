// node modules
import React, {FC} from 'react';

// local components
import { SimpleButton } from '../simple_button';

// local files
import { DEFAULT_MULTI_CLICK_PROTECTOR_DELAY } from "../../../utils/constants";
import { useMultiClickProtector } from "../../../helpers/hooks/useMultiClickProtector";
import { useStyles } from './styles';
import { theming } from "../../../theme";

export interface PrimaryButtonProps {
    label: string;
    multiClickProtector?: boolean
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    children?: any;
    delayNextClick?: number;
    onClick?: (props?: any) => any;
    multiClickProtectorDelay?: number;
}


export const PrimaryButton: FC<PrimaryButtonProps> = ({
    className = '',
    label = '',
    children,
    multiClickProtector = true,
    multiClickProtectorDelay = DEFAULT_MULTI_CLICK_PROTECTOR_DELAY,
    onClick = () => {},
    ...otherProps
}) => {
    const { handleMultiClickProtector } = useMultiClickProtector(multiClickProtectorDelay);
    const theme = theming.useTheme();
    const { root } = useStyles({ theme });
    const primaryBtnClassName = `${root} ${className}`;

    const handleClick = multiClickProtector ? handleMultiClickProtector(onClick) : onClick;

    return (
        <SimpleButton
            onClick={handleClick}
            className={primaryBtnClassName}
            label={label} {...otherProps}
        >
            {children}
        </SimpleButton>
    )
}
