// node modules
import { createUseStyles } from "react-jss";

import { Theme } from "../../../theme";

export const useStyles = createUseStyles({
    root: ({ theme }: Theme) => {
        const { checkButton = () => ({}) } = theme;

        return {...checkButton(theme)}
    },
    checkWrapper: {},
    checkIconCl: {},
    childrenStyle: {},
    centeredChildren: {},
    selectedClass: {}
});
