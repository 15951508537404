export const setCorrectUppercaseLowercase = (str: string) => {
    let newString = str.replace(/\^_([\s\S]*?)_\^/g, (_: string, key: string) => {
        const trimValue = key.trim();

        return trimValue.charAt(0).toLowerCase() + trimValue.slice(1);
    })

    newString = newString.replace(/\^-([\s\S]*?)-\^/g, (_: string, key: string) => {
        const trimValue = key.trim();

        return trimValue.charAt(0).toUpperCase() + trimValue.slice(1);
    })

    return newString
}