import React from "react";

import { useStyles } from "./styles";

type DividerProps = {
    dataDividerText?: string
}

export const Divider = ({ dataDividerText = 'OR' }: DividerProps) => {
    const { divider } = useStyles();

    return <div className={divider} data-divider-text={dataDividerText}/>
}