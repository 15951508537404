import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    divider: {
        position: 'relative',
        height: 1,
        width: '100%',
        background: '#C7C7C7',
        margin: '19px auto 19px auto',
        '&:after': {
            content: 'attr(data-divider-text)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '0 10px',
            background: 'white',
            fontSize: 14
        }
    }
});
