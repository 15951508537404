// node modules
import { createUseStyles } from "react-jss";

// local files
import { alignment } from "../../../theme/alignment";

export const useStyles = createUseStyles({
   root: {
       ...alignment.center,
       position: 'fixed',
       top: 0,
       left: 0,
       right: 0,
       bottom: 0,
       background: 'rgba(85, 84, 85, 0.8);',
       zIndex: 10000
   }
});
