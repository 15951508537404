// node modules
import React, { FC } from 'react';

// local components
import { SimpleButton } from '../simple_button';

// local files
import { DEFAULT_MULTI_CLICK_PROTECTOR_DELAY } from "../../../utils/constants";
import { useMultiClickProtector } from "../../../helpers/hooks/useMultiClickProtector";
import { useStyles } from './styles';
import { theming } from "../../../theme";

export interface SelectButtonProps {
    label: string;
    selected?: boolean;
    className?: string;
    loading?: boolean;
    disabled?: boolean;
    children?: any;
    centered?: boolean;
    animationTime?: number,
    multiClickProtector?: boolean,
    multiClickProtectorDelay?: number;
    onClick?: (props?: any) => any;
}

export const SelectButton: FC<SelectButtonProps> = ({
    className,
    selected = false,
    children,
    centered= false,
    multiClickProtector = true,
    onClick = () => {},
    multiClickProtectorDelay = DEFAULT_MULTI_CLICK_PROTECTOR_DELAY,
    animationTime = DEFAULT_MULTI_CLICK_PROTECTOR_DELAY,
    ...otherProps
}) => {
    const { root, rootSelected } = useStyles({
        theme: { ...theming.useTheme(), animationTime }
    });
    const { handleMultiClickProtector } = useMultiClickProtector(multiClickProtectorDelay);
    const selectedClass =  selected ? rootSelected : '';
    const selectBtnClass = `${root} ${selectedClass} ${className}`;

    const handleClick = multiClickProtector ? handleMultiClickProtector(onClick) : onClick;

    return (
        <SimpleButton onClick={handleClick} centered={centered} className={selectBtnClass} {...otherProps}>
            {children}
        </SimpleButton>
    )
}