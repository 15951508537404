import React, {useLayoutEffect, useState} from "react";

import { Content } from "./content";

import { scriptLoader } from "./script_loader";

export type PayPalProps = {
    setPayPalLoaded: (arg: boolean) => void,
    braintreeAuthorization?: string,
    onSubmit: any,
    paypalButtonProps: any
}

export const PayPal = (props: PayPalProps) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useLayoutEffect(() => {
        scriptLoader(setScriptLoaded);
    }, []);

    if(!scriptLoaded) return null;

    return <Content {...props} />;
}