export const replaceLinks = (str: string) => {
    return str.replace(/\[([\s\S]*?)]/g, (_, key) => {
        const [link, linkName] = key.split('|')

        if (link && linkName) {
            return `<a target="_blank" href="${link.trim()}">${linkName.trim()}</a>`
        }

        return `[${key}]`
    });
}
