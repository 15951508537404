// node modules
import React, { FC } from 'react';

// local files
import { ModalWindowProps } from "../";
import { useStyles } from "./styles";

export const DialogContent: FC<ModalWindowProps> = ({
     children,
     open= false,
     rootClassName = ''
 }) => {
    const { root } = useStyles();
    document.body.style.overflow = open ? 'hidden': '';

    return open ? <div className={`${root} ${rootClassName}`}>{ children } </div> : null;
}