// node modules
import { createUseStyles } from 'react-jss'

// local files
import { Theme } from "../../../theme";

export const useStyles = createUseStyles({
    root: {
        outline: 'none',
        border: 'none',
        background: 'none',
        fontSize: 16,
        padding: '18px',
        cursor: 'pointer',
        transition: 'all .3s ease',
        minWidth: 150,
        '@media (max-width: 365px)': {
            minWidth: 136
        },
        position: 'relative',
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
        '&>p': {
            lineHeight: '20px',
        }
    },
    disabledClass: {
        cursor: 'not-allowed',
        color: ({ theme: { palette } }: Theme) => [[palette.black], '!important'],
        background: ({ theme: { palette } }) => [[palette.light_gray], '!important']
    },
    centeredLabel: {
        textAlign: 'center',
        width: '100%',
    },
    loader: {
        width: 20,
        height: 20
    }
})
