export type Font = {
    fontSize: string
    fontWeight?: number
    lineHeight?: string
}

export const font: Record<string, Font> = {
    font16Medium: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px'
    },
    font16Bold: {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px'
    },
    font32Medium: {
        fontSize: '32px',
        fontWeight: 400,
    },
    font24Medium: {
        fontSize: '24px',
        fontWeight: 400,
    },
    font20Medium: {
        fontSize: '20px',
        lineHeight: '26px'
    },
    font18Medium: {
        fontSize: '18px'
    },
    font14Medium: {
        fontSize: '14px',
        lineHeight: '18px'
    },
    font12Medium: {
        fontSize: '12px',
        lineHeight: '16px'
    }
}