// node modules
import React, { FC, ReactNode } from 'react';
import { createUseStyles } from "react-jss";

import { theming, defaultTheme, ThemeProps } from "../../theme";

export interface ThemeProviderProps {
    theme?: ThemeProps
    children: ReactNode
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ theme, children }: ThemeProviderProps) => {
    const { ThemeProvider } = theming
    const { typography } = theme || {};
    const commonTheme = theme ? { ...defaultTheme, ...theme } : defaultTheme;

    commonTheme.palette = {
        ...defaultTheme.palette,
        ...(theme?.palette || {})
    }

    commonTheme.typography = typography ? { ...defaultTheme.typography, ...typography } : defaultTheme.typography;

    return (
        <ThemeProvider theme={commonTheme}>
            {children}
        </ThemeProvider>
    )
};

export const makeStyles = (styles: any) => {
    return createUseStyles(
        styles,
        { theming }
    )
}