import {useState} from "react";

export const useDelayAction = () => {
    const [actionAvailable, setActionAvailability] = useState(true);

    return {
        availabilityWatcher: (action: (prop?: any) => any, delay: number) => {
            if (!actionAvailable) return;

            action();
            setActionAvailability(false);
            setTimeout(() => setActionAvailability(true), delay)
        }
    }
}