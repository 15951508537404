export const REGION = {
    de: 'de',
    uk: 'uk',
    jp: 'jp',
    us: 'us',
};

export const getScriptUrl = (isSandbox: boolean, region = REGION.us) => {
    const sandbox = isSandbox ? 'sandbox/' : '';

    switch (region.toLowerCase()) {
        case 'de':
            return `https://static-eu.payments-amazon.com/OffAmazonPayments/de/${sandbox}lpa/js/Widgets.js`;
        case 'uk':
            return `https://static-eu.payments-amazon.com/OffAmazonPayments/uk/${sandbox}lpa/js/Widgets.js`;
        case 'jp':
            return `https://static-fe.payments-amazon.com/OffAmazonPayments/jp/${sandbox}lpa/js/Widgets.js`;
        default:
            return `https://static-na.payments-amazon.com/OffAmazonPayments/us/${sandbox}js/Widgets.js`;
    }
};

export const appendScript = (isSandbox: boolean, region = REGION.us, loadBtn: any) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = getScriptUrl(isSandbox, region);
    script.async = true;

    script.addEventListener('load', loadBtn);

    document.body.appendChild(script);
};

