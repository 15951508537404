// node modules
import React from 'react';
import chroma from 'chroma-js';

// local files
import checkIcon from '../../../assets/check_icon_progress_white.svg';
import { palette } from "../../../theme/palette";

export const getCorrectInput = (i: number, steps: number, percents: number) => {
    if (!i) return '';

    if (i / steps * 100 <= percents) return <img style={{ width: 7, marginLeft: '.5px' }} src={checkIcon} alt="" />;

    return i;
}

export const getCorrectInputNewOnboarding = (i: number) => {
    if (!i) return '';

    return <img style={{ width: 7, marginLeft: '.5px' }} src={checkIcon} alt="" />;
}

export const getCorrectColor = (i: number, steps: number, percents: number, colors: string[] | string, backgroundColor?: string) => {
    if (i / steps * 100 <= percents) {
        return !colors[i - 1] ? colors[i] : colors[i - 1]
    }

    return backgroundColor || palette.gray_400
}

export const getIndexColorToTitle = (correctPercents: number, steps: number, colors: string[] | string) => {
    const correctiveForPercents = 0.33;
    let index = Math.floor(steps * (correctPercents - correctiveForPercents) / 100);

    if (index === colors.length) {
        index = index - 1;
    }

    return index;
}

export const getGradientPointColor = (i: number, steps: number, percents: number, backgroundColor: string | undefined, gradientColors: string[] | undefined) => {
    if (i / steps * 100 <= percents) {
        return chroma.scale(gradientColors)(i / steps).toString()
    }
    return backgroundColor || palette.gray_400
}