import React, {useEffect, useState} from "react";

import { PayPalProps } from "../";
import { useStyles } from "./styles";
import {PrimaryButton} from "../../../buttons/primary_button";

export const Content = ({
    onSubmit,
    braintreeAuthorization,
    setPayPalLoaded,
    paypalButtonProps
}: PayPalProps) => {
    const [loading, setLoading] = useState(false);
    const { payPal, payPalLoading, hide } = useStyles();


    useEffect(() => {

        // @ts-ignore
        window.braintree?.client.create({
            authorization: braintreeAuthorization
        }).then((clientInstance: any) => {
            // @ts-ignore
            return window.braintree?.paypalCheckout.create({
                client: clientInstance
            });
        }).then((paypalCheckoutInstance: any) => {
            return paypalCheckoutInstance.loadPayPalSDK({
                currency: 'USD',
                intent: 'capture',
                vault: true,
            }).then(() => paypalCheckoutInstance);
        }).then((paypalCheckoutInstance: any) => {
            //@ts-ignore
            window.paypal?.Buttons({
                style: paypalButtonProps,
                //@ts-ignore
                fundingSource: window.paypal.FUNDING.PAYPAL,
                createBillingAgreement: function () {
                    return paypalCheckoutInstance.createPayment({
                        flow: 'vault', // Required
                        enableShippingAddress: true,
                    });
                },
                onApprove: function (data: any) {
                    setLoading(true);
                    return paypalCheckoutInstance.tokenizePayment(data, (_: any, payload: any) =>
                        onSubmit({ ...payload, type: 'payPal' }).then(() => {
                            setLoading(false);
                        })
                    );
                },
                onCancel: function (data: any) {
                    //@ts-ignore
                    console.log('PayPal payment canceled', JSON.stringify(data, 0, 2));
                },
                onError: function (err: any) {
                    console.error('PayPal error', err);
                }
            }).render('#paypal-button');

        }).then(() => {
            setPayPalLoaded(true);
        }).catch(function (err: any) {
            console.error('Error! PayPal', err);
        });
    }, []);

    return <>
        <PrimaryButton className={`${payPalLoading} ${!loading? hide: ''}`} loading={loading} label={''} />
        <div className={`${payPal} ${loading? hide: ''}`} id="paypal-button"/>
    </>
}
