// node modules
import React, {FC} from "react";

// local components
import { SelectButton } from "../select_button";

// local files
import { theming } from "../../../theme";
import { useStyles } from "./styles";

export interface CheckButtonProps {
    label: string;
    className?: string;
    selected?: boolean;
    loading?: boolean;
    disabled?: boolean;
    centered?: boolean;
    children?: any;
    onClick?: () => any;
}

export const CheckButton: FC<CheckButtonProps> = ({
    label = '',
    className = '',
    selected = false,
    centered= false,
    children,
    ...otherProps
}) => {
    const {
        root, checkWrapper, checkIconCl,
        childrenStyle, centeredChildren, selectedClass
    } = useStyles({
        theme: theming.useTheme()
    });
    const checkBtnClass = `${root} ${className} ${selected ? selectedClass : ''}`

    return (
        <SelectButton centered={centered} selected={selected} label={''} className={checkBtnClass} {...otherProps}>
            <div className={`${childrenStyle} ${centered ? centeredChildren : ''}`}>{children || label}</div>
            <div className={checkWrapper}>
                { selected && (
                    <svg
                        className={checkIconCl}
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17 1.67407L6 12.6741L1 7.67407"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                )}
            </div>
        </SelectButton>
    )
};