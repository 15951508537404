// node modules
import { createUseStyles } from "react-jss";

// local files
import { Theme } from "../../theme";

export const useStyles = createUseStyles({
    errorMessageClass: (theming : Theme) => {
        const { theme } = theming;
        const { inputErrorTextClass = () => ({}) } = theme;

        return {
            textAlign: 'left',
            padding: '3px 0',
            color: theme.palette.error,
            marginTop: '8px',
            ...inputErrorTextClass(theme)
        }
    },
    validatedInputWrapper: {
        width: '100%'
    },
    hidden: {
        display: "none",
    },
});
