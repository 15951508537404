import { createUpdateStorageCustomEvent } from '../events/updateLocalStorage.event';
import {UPDATE_STORAGE_EVENT} from "../../constants/events";

class LocalStorageService {
    getItem(name: string) {
        return localStorage.getItem(name)
    }

    setItem(name: string, value: any) {
        localStorage.setItem(name, value);
        window.dispatchEvent(createUpdateStorageCustomEvent(value, name))
    }

    intitWatchVP (watchingLocalStoradgeKey: string) {
        return (watchers: ((value: any) => any)[]) => {
            window.addEventListener(UPDATE_STORAGE_EVENT, (e:any) => {
                    if(watchingLocalStoradgeKey === e.detail.keyEvent) {
                        watchers.forEach((watcher) => watcher(e.detail.value))
                    }
                })
            }
    }

    getLocalStorageData(keys: string[]) {
        const storageData = keys.reduce((data: any, key: string) => {
            try {
                const value = localStorage.getItem(key)
                data[key] = JSON.parse(value as string)
            } catch {
                data[key] = localStorage.getItem(key)
            }

            return data;
        }, {})

        return storageData;
    }
}

export const localStorageService = new LocalStorageService();