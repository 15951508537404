// node modules
import React, { FC, Fragment } from 'react';

// local components
import { Typography } from "../../typography";

// local files
import { theming } from "../../../theme";
import {
    getCorrectColor,
    getCorrectInput,
    getGradientPointColor,
    getIndexColorToTitle,
    getCorrectInputNewOnboarding
} from "./utils";
import { palette } from "../../../theme/palette";
import { uniqIdGenerator } from "../../../utils/local_utils";
import leftArrow from '../../../assets/left_arrow.svg';
import arrowLeftNewOnboarding from '../../../assets/arrow_left.svg';
import leftArrowOutlined from '../../../assets/back_btn_oulined.svg';
import { TypographyVariants } from "../../../constants";
import { useStyles } from "./styles";

export interface ProgressStepperProps {
    steps: number,
    colors: string[] | string;
    percents: number;
    title: string,
    backgroundColor?: string,
    backButton?: boolean,
    backButtonOutlined?: boolean,
    handleBack?: () => void,
    stepperHeight?: number,
    gradient?: boolean,
    gradientColor?: string[],
    titleClass?: string
}


export const ProgressStepper: FC<ProgressStepperProps> = ({
    steps,
    colors,
    percents = 0,
    title,
    backgroundColor,
    backButton,
    handleBack,
    stepperHeight,
    backButtonOutlined,
    gradient = false,
    gradientColor = ['#00A3FF', '#066FDE'],
    titleClass = ''
}) => {
    const {
        wrapper,
        cercle,
        bar,
        coverBlock,
        thinBlock,
        root,
        backBtn,
        paddingWrapper,
        backBtnNewOnboarding,
        progressTitle,
        wrapperNewOnboarding
    } = useStyles({ theme: theming.useTheme() })
    const generatedArray = new Array(steps + 1).fill(true);
    let correctPercents = percents <= 0 || isNaN(percents) ? 1 : percents;
    correctPercents = correctPercents > 100 ? 100 : correctPercents;

    return (
        <div className={paddingWrapper}>
            <div className={root}>
                {gradient && <Typography
                    className={progressTitle}
                    variant={TypographyVariants.h4}
                >
                    {title}
                </Typography>}
                {backButton && gradient && (
                    <img
                        className={backBtnNewOnboarding}
                        onClick={handleBack}
                        src={arrowLeftNewOnboarding}
                        alt=""
                    />
                )}
                <div className={`${wrapper} ${gradient ? wrapperNewOnboarding : ''}`}>
                    {gradient && (
                        <>
                            <span
                                style={{
                                    left: 0,
                                    width: `${percents >= 100 ? 100 : percents}%`,
                                    background: `linear-gradient(to right, ${gradientColor[0]}, ${gradientColor[1]})`,
                                    zIndex: steps + 1,
                                    height: stepperHeight,
                                    borderRadius: gradient ? 8 : 'initial'
                                }}
                                className={bar}
                            />
                            <span
                                style={{
                                    left: 0,
                                    width: `${100 - percents <= 0 ? 0 : 100 - percents}%`,
                                    background: backgroundColor,
                                    zIndex: steps,
                                    height: stepperHeight
                                }}
                                className={bar}
                            />
                        </>
                    )}
                    {generatedArray.map((_, i) => {
                        return (
                            <Fragment key={uniqIdGenerator()}>
                                {!!i && !gradient && (<span
                                    style={{
                                        left: 0,
                                        width: `calc(${i}/${steps} * 100%)`,
                                        background: colors[i - 1],
                                        zIndex: steps + 1 - i,
                                        height: stepperHeight
                                    }}
                                    className={bar}
                                />)}
                                <span
                                    style={{
                                        left: `calc(${i}/${steps} * 100%)`,
                                        zIndex: 20,
                                        background: gradient
                                            ? getGradientPointColor(i, steps, percents, backgroundColor, gradientColor)
                                            : getCorrectColor(i, steps, percents, colors, backgroundColor),
                                        color: '#202B47'
                                    }}
                                    className={cercle}
                                >
                                    {gradient ? getCorrectInputNewOnboarding(i) : getCorrectInput(i, steps, correctPercents)}
                                </span>
                            </Fragment>
                        )
                    })}
                    <div
                        style={{
                            zIndex: generatedArray.length + 1, width: `calc(100% - ${correctPercents}%)`,
                            background: backgroundColor || palette.white,
                            height: stepperHeight
                        }}
                        className={coverBlock}
                    >
                        <div
                            style={{
                                background: backgroundColor || palette.gray_400,
                                height: stepperHeight
                            }}
                            className={thinBlock}
                        />
                    </div>
                </div>
                {!gradient && <Typography
                    style={{ color: colors[getIndexColorToTitle(correctPercents, steps, colors)] }}
                    variant={TypographyVariants.h4}
                    className={titleClass}
                >
                    {title}
                </Typography>}
                {backButton && !gradient && (
                    <img
                        className={backBtn}
                        onClick={handleBack}
                        src={leftArrow}
                        alt=""
                    />
                )}
                {backButtonOutlined && !backButton && (
                    <img
                        className={backBtn}
                        onClick={handleBack}
                        src={leftArrowOutlined}
                        alt=""
                    />
                )}
            </div>
        </div>
    )
};
