// node modules
import { createUseStyles } from 'react-jss'

// local files
import { Theme } from "../../../theme";

export const useStyles = createUseStyles({
    root: ({ theme }: Theme) => {
        const { primaryButton = () => ({})}  = theme;

        return { ...primaryButton(theme) };
    }
});
