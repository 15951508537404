// local files
import { TextNormilizerOptions } from "./interfaces";

export const replaceVariables = (someStr: string, options: TextNormilizerOptions) => {
    const { variables, localStorageMode } = options;

    return someStr.replace(/%_([\s\S]*?)_%/g, (_, key) => {
        if (localStorageMode) {
            const valueFromLocalStorage = localStorage.getItem(key);

            if (valueFromLocalStorage) {
                return valueFromLocalStorage;
            }
        }

        if (variables && variables[key]) {
            return variables[key].toString()
        }

        return '_';
    });
}
