import { useEffect, useState } from "react";

import { IsJsonString } from "../functions/json_validator";
import { UPDATE_STORAGE_EVENT } from "../../constants/events";
import { createUpdateStorageCustomEvent } from "../events/updateLocalStorage.event";

const getDefaultValue = (key: string, defaultValue: any) => {
    const data = localStorage.getItem(key)
    if (data) {
        if (IsJsonString(data)) {
            return JSON.parse(data)
        }else{
            return data
        }
    } else {
        return defaultValue
    }
}

type StorageParams = {
    key: string;
    defaultValue: any;
}

export const useLocalStorage = ({ key, defaultValue }: StorageParams) => {
    const [pageValue, setPageValue] = useState(getDefaultValue(key, defaultValue))

    useEffect(() => {
        window.addEventListener(UPDATE_STORAGE_EVENT, (e:any) => {
            if(key === e.detail.keyEvent) {
                setPageValue(e.detail.value)
            }
        })

        return () => {
            window.removeEventListener(UPDATE_STORAGE_EVENT, (e:any) => {
                if(key === e.detail.keyEvent) {
                    setPageValue(e.detail.value)
                }
            })
        }
    }, [key])

    useEffect(() => {
        if (typeof pageValue === 'object') {
            localStorage.setItem(key, JSON.stringify(pageValue) || '')
        } else {
            localStorage.setItem(key, typeof pageValue === 'boolean' ? pageValue : pageValue || '')
        }
        window.dispatchEvent(createUpdateStorageCustomEvent(pageValue, key))
    }, [pageValue, key])

    return {pageValue, setPageValue}
}
