import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    cardList: {
        display: 'flex',
        width: 213,
        margin: '16px auto 0',
        justifyContent: 'space-between',
        paddingBottom: 16,
        '& img': {
            height: 25
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderBottom: '1px solid #C7C7C7'
    },
    divider: {
        height: 1,
        background: '#C7C7C7'
    },
     title: {
        fontSize: 18
     }
});