// node modules
import React, { FC, useEffect, useState } from 'react';
import parser from 'html-react-parser';

// local files
import { Typography } from "../../typography";
import { TextNormalizer } from "../../parser";

// local files
import { useStyles } from "./styles";
import { TypographyVariants } from "../../../constants";


export type SliderProps = {
    image?: boolean;
    value: string | JSX.Element;
    delay?: number;
    color?: string;
    variant?: TypographyVariants
}

interface SlideContentProps {
    handleNext: () => void;
    content: SliderProps;
    color?: string;
    rootSlideClass?: string;
}

let timerWaiting: ReturnType<typeof setTimeout>,
    timerHandleNext:  ReturnType<typeof setTimeout>;

const waiting = (delay: number) => {
    return new Promise(resolve => {
        timerWaiting = setTimeout(resolve, delay)
    })
}

const ELEMENT_DELAY = 3000;
const TIMEOUT_DELAY = 1800;

export const Slide: FC<SlideContentProps> = ({ handleNext, content, rootSlideClass, color }) => {
    const { slideClass, slideShowed, typ, imageWrapper } = useStyles();
    const [activeClass, setActiveClass] = useState(false);

    useEffect(() => {
        const { delay } = content;

        waiting(delay || ELEMENT_DELAY).then(() => {
            setActiveClass(false);
            timerHandleNext = setTimeout(handleNext, TIMEOUT_DELAY)
        });

        setActiveClass(true);

        return () => {
            clearTimeout(timerWaiting);
            clearTimeout(timerHandleNext);
        }
    }, [content]);

    const className = `${slideClass} ${rootSlideClass} ${activeClass ? slideShowed : ''}`;

    return (
        <div className={className}>
            {typeof content.value !== 'string'
                ? <div className={imageWrapper}>{content.value}</div>
                : (
                    <Typography
                        style={{ color: content.color || color || null }}
                        variant={content.variant || TypographyVariants.bigTitle}
                        className={typ}
                    >
                        {parser(TextNormalizer(
                            content.value,
                            {
                                brChanger: true,
                                localStorageMode: true
                            }
                        ))}
                    </Typography>
                )
            }

        </div>
    )
};
