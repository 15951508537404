import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    amazonButton: {
        borderRadius: 10,
        position: 'relative',
        minHeight: 48,
        cursor: 'pointer',
        marginBottom: 18
    },
    amazonButtonBack: {
        width: '100%',
        height: 48,
        borderRadius: 10,
        position: 'absolute',
        top: 0,
        left: 0,
        background: '#11273B',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none'
    }
});
