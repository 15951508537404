import {Theme} from "./index";

const body1 = {
    color: ({ theme: { palette } }: Theme) => palette.black,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    fontFamily: 'SF Pro Text',
    margin: 0
}

const h3 = {
    ...body1,
    fontWeight: 600,
    '&:first-letter': {
        'text-transform': 'capitalize'
    },
    color: ({ theme: { palette } }: Theme) => palette.dark_black
}

const h4 = {
    ...h3,
    fontSize: '14px',
    lineHeight: '18px',
    '&:first-letter': {
        'text-transform': 'capitalize'
    }
}

const h2 = {
    fontWeight: 600,
    margin: 0,
    fontFamily: 'SF Pro Text',
    fontSize: '22px',
    lineHeight: '30px',
    '&:first-letter': {
        'text-transform': 'capitalize'
    },
    color: ({ theme: { palette } }: Theme) => palette.dark_black,
}

const label = {
    margin: 0,
    fontFamily: 'SF Pro Text',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    '&:first-letter': {
        'text-transform': 'capitalize'
    },
    color: ({ theme: { palette } }: Theme) => palette.dark_black
}

const title = {
    padding: 0,
    fontWeight: 600,
    fontSize: 16,
    fontFamily: 'SF Pro Text',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '-0.0024em',
    margin: 0,
    '&:first-letter': {
        'text-transform': 'capitalize'
    }
}

const bigTitle = {
    ...title,
    fontSize: 34,
    lineHeight: '40px'
}


export const typography = {
    body1,
    h2,
    h3,
    h4,
    label,
    title,
    bigTitle
}