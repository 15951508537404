// node modules
import { createUseStyles } from "react-jss";

// local files
import { Theme } from '../../../theme';

export const useStyles = createUseStyles({
    wrapper: {
        position: 'relative',
        height: 48,
        borderRadius: '.5rem',
        background: ({ theme: { palette } }: Theme) => palette.light_gray,
    },
    text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: ({ theme: { palette } }) => palette.white,
        zIndex: 1
    },
    childBar: {
        maxWidth: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        borderRadius: '.5rem',
        background: ({ theme: { palette } }) => palette.primary,
        transition: 'width .3s'
    }
});