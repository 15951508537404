// local files
import { replaceTags } from "./tag_replacer";
import { ParserProps, TextNormilizerOptions } from "./interfaces";

export const generateParser = (options: TextNormilizerOptions) => {
    const parser: ParserProps = {};

    const { tagWrappers, brChanger } = options;

    if (tagWrappers) {
        if (typeof tagWrappers[0] === 'string') {
            parser.tagChangers = [replaceTags(tagWrappers)];
        }

        if (typeof tagWrappers[0] === 'object') {
            // @ts-ignore
            parser.tagChangers = tagWrappers.map((wrapper: [string, string]) => replaceTags(wrapper))
        }
    }

    if (brChanger) {
        parser.brChanger = (s: string) => s.replace(/\n/g, '<br>');
    }

    return parser;
}
