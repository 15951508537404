import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    amazonPayButton: {
        width: '100%',
        height: 48,
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden',
        zIndex: 2,
        opacity: 0
    }
});
