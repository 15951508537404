import React from 'react';

import loaderImg from "../../assets/paywall/loader.svg";

import { useStyles } from "./styles";

export const LoaderBox = () => {
    const { loader, loaderWrapper } = useStyles();

    return (
        <div className={loaderWrapper}>
            <img className={loader} src={loaderImg} alt="loader"/>
        </div>
    )
}
