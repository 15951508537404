import React from "react";

import { AmazonPaymentButton } from "./amazon_payment_button";

import amazonIcon from '../../../assets/paywall/amazon-pay-icon.svg';
import { useStyles } from "./styles";

export type AmazonButtonProps = {
    onError?: () => void,
    sellerId: string,
    clientId: string,
    env: string,
    onAuth: () => void
}

export const AmazonButton = (props: AmazonButtonProps) => {
    const { amazonButton, amazonButtonBack } = useStyles();

    return (
        <div className={amazonButton}>
            <AmazonPaymentButton {...props} />
            <button className={amazonButtonBack}>
                <img src={amazonIcon} alt='' />
            </button>
        </div>
    )
}