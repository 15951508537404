import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    payPal: {
        marginBottom: 12,
    },
    payPalLoading: {
        width: '100%',
        height: '45px',
        background: '#ffc439',
        borderRadius: '4px',
        marginBottom: '16px',
        padding: '14px',
        "&:hover": {
            filter: 'brightness(0.95)',
            background: '#ffc439'
        }
    },
    hide: {
        display: 'none'
    }
});
