// node modules
import { createUseStyles } from "react-jss";

// local files
import { alignment } from "../../theme/alignment";
import { Theme } from "../../theme";

export const useStyles = createUseStyles({
   wrapper: {
     minWidth: 200,
   },
   tabWrapper: {
      ...alignment.betweenCenter,
      height: 32,
      padding: 2,
      borderRadius: 8,
      background: ({ theme: { palette } }: Theme) => palette.light,
   },
   activeBtn: {
      background: ({ theme: { palette } }) => palette.white
   },
   btn: {
      minWidth: 'auto',
      padding: 0,
      height: '100%',
      borderRadius: 8
   }
})
