// node modules
import { createUseStyles } from "react-jss";

// local files
import { Theme } from "../../../theme";
import { alignment } from "../../../theme/alignment";

export const useStyles = createUseStyles({
    wrapper: {
        width: '100%',
        minWidth: 200,
        position: 'relative',
        height: 6,
        marginBottom: 17
    },
    wrapperNewOnboarding: {
        marginBottom: 0
    },
    cercle: {
        fontFamily: 'SF Pro Text',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: 16,
        height: 16,
        borderRadius: '50%',
        ...alignment.center,
        fontSize: 10,
        boxSizing: 'border-box',
        lineHeight: 0,
        color: ({ theme: { palette } }: Theme) => palette.black
    },
    bar: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        height: 8
    },
    coverBlock: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        right: 0,
        width: '100%',
        height: 8,
        ...alignment.center,
        transition: 'width .3s',
        minWidth: 0
    },
    thinBlock: {
        width: '100%',
        height: 6
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    paddingWrapper: {
        padding: '5px 8px 0',
        position: 'relative'
    },
    backBtn: {
        position: 'absolute',
        top: 25,
        left: -6
    },
    backBtnNewOnboarding: {
        position: 'absolute',
        top: 0,
        left: -6
    },
    progressTitle: {
        color: '#066FDE',
        textTransform: 'uppercase',
        paddingBottom: 14
    },
    '@media screen and (min-width: 768px)': {
        backBtn: {
            position: 'absolute',
            top: 25,
            left: 0
        }
    }
})
