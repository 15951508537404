// local files
import { replaceLinks } from './link_replacer';
import { generateParser } from './parser_generator';
import { replaceVariables } from './variables_replacer'
import { ParserProps, TextNormilizerOptions } from "./interfaces";
import { setCorrectUppercaseLowercase } from "./set_correct_uppercase_lowercase";

export const TextNormalizer = (str: string, options: TextNormilizerOptions) => {
    let newString = str;

    const parser: ParserProps = generateParser(options);

    newString = replaceVariables(newString, options);

    newString = replaceLinks(newString);

    newString = setCorrectUppercaseLowercase(newString);

    if (parser.tagChangers) parser.tagChangers.forEach(cb => newString = cb(newString));

    if (parser.brChanger) newString = parser.brChanger(newString);

    return newString
}
