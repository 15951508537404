// node modules
import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    imageWrapper: {

    },
    slideClass: {
        transition: 'opacity 1s',
        opacity: 0,
        overflow: 'hidden'
    },
    slideShowed: {
        opacity: 1
    },
    typ: {
        textAlign: 'left'
    },
});
