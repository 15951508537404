// node modules
import React, { FC } from 'react';

// local components
import { DialogContent } from "./dialog_content";

export interface ModalWindowProps {
    children?: any;
    open?: boolean;
    rootClassName?: string;
}

export const ModalWindow: FC<ModalWindowProps> = (props) => {
    return <DialogContent {...props} />
}
