// node modules
import { createUseStyles } from "react-jss";

// local files
import SFProText from '../../assets/fonts/SF-Pro-Text-Regular.otf';
import { Theme } from "../../theme";

export const useStyles = createUseStyles({
    '@font-face': {
        fontFamily: "SF Pro Text",
        src: `url('${SFProText}') format("opentype")`
    },
    body1: ({ theme }: Theme) => theme.typography.body1,
    button: ({ theme }: Theme) => {
        return {
            ...theme.typography.body1,
            color: 'inherit'
        }
    },
    h2: ({ theme }) => theme.typography.h2,
    h3: ({ theme }) => theme.typography.h3,
    h4: ({ theme }) => theme.typography.h4,
    label: ({ theme }) => theme.typography.label,
    title: ({ theme }) => theme.typography.title,
    bigTitle: ({ theme }) => theme.typography.bigTitle
});
