// node modules
import { createUseStyles } from "react-jss";

// local files
import { alignment } from "../../theme/alignment";
import { Theme } from "../../theme";

export const useStyles = createUseStyles({
    wrapper: {
        ...alignment.center,
        border: ({ theme: { palette } }: Theme) => `2px solid ${palette.gray_300}`,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 10,
        width: 160,
        paddingBottom: 15,
        paddingTop: 16,
        boxSizing: 'border-box'
    },
    content: {
        ...alignment.rowCenter,
        width: '100%'
    },
    icon: {
        marginTop: 12,
        width: 23
    },
    btn: {
        minWidth: 'auto',
        width: 'calc(100% - 28px)',
        background: ({ theme: { palette } }) => palette.gray_400,
        color: ({ theme: { palette } }) => palette.gray_500,
        marginTop: 20,
        borderRadius: '10px',

        '& p': {
            fontSize: 14,
            lineHeight: '18px',
        },
        '&:active': {
            background: ({ theme: { palette } }: Theme) => palette.primary,
            color: ({ theme: { palette } }) => palette.light
        }
    },
    activeBtn: {
        background: ({ theme: { palette } }: Theme) => palette.primary,
        color: ({ theme: { palette } }) => palette.light,
        '&:active': {
            background: ({ theme: { palette } }) => palette.gray_400,
            color: ({ theme: { palette } }) => palette.gray_500,
        }
    },
    activeImg: {
        width: 16
    },
    typClass: {
        marginTop: 12,
        color: ({ theme: { palette } }) => palette.gray_500,
        fontWeight: 400,
        fontSize: '14px'
    },
    activeTotalSavingTitle: {
        fontSize: 16,
        lineHeight: '24px'
    },
    priceWrapperActive: {
      fontWeight: 600
    },
    priceWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 600,
    },
    wrapperActive: {
        borderColor: ({ theme: {  palette } }) => palette.primary,
        position: 'relative'
    },
    activePopUp: {
        ...alignment.center,
        position: 'absolute',
        top: -33,
        left: -2,
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        lineHeight: '14px',
        fontWeight: 600,
        background: ({ theme: { palette } }) => palette.primary,
        height: 32,
        width: 'calc(100% + 4px)',
        color: ({ theme: { palette } }) => palette.white
    },
    priceTextStyle: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 600,
    },
})