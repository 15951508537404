// node modules
import React, { FC, ReactNode } from "react";

//local components
import { Typography } from "../../typography";

// local files
import { useStyles } from "./styles";
import { theming } from "../../../theme";
import { useDelayAction } from "../../../helpers/hooks/delayAction";
import loaderImg from '../../../assets/loader.svg';
import { TypographyVariants } from "../../../constants";

export interface SimpleButtonProps {
    label: any;
    className?: string;
    width?: number | string;
    disabled?: boolean;
    centered?: boolean;
    onClick?: () => void;
    children?: string | ReactNode;
    loading?: boolean;
    delayNextClick?: number;
    textTransform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none'
}

export const SimpleButton: FC<SimpleButtonProps> = ({
    className = '',
    label,
    width,
    disabled,
    centered = false,
    onClick,
    children,
    delayNextClick,
    loading,
    textTransform = 'none',
    ...otherProps
}) => {
    const { root, disabledClass, centeredLabel, loader } = useStyles({ theme: theming.useTheme() });
    const btnClassName = `${root} ${className} ${disabled && disabledClass}`;
    const { availabilityWatcher } = useDelayAction();
    const handleClick = () => {
        if (disabled || loading) return;

        if (onClick) availabilityWatcher(onClick, delayNextClick || 500);
    }

    return (
        <button
            onClick={handleClick}
            style={{ width, textTransform }}
            className={btnClassName}
            {...otherProps}
        >
            {
                loading
                    ? (<img src={loaderImg} className={loader} alt="loader" />)
                    : (
                        <>
                            {label
                                ? (
                                    <Typography
                                        variant={TypographyVariants.button}
                                        className={centered ? centeredLabel : ''}>
                                        {label}
                                    </Typography>
                                )
                                : null
                            }
                            {children}
                        </>
                    )
            }
        </button>
    )
};
