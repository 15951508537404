//#TODO move destination service to library, because this is very sensetive logic.
import jsep from "jsep";

import { evaluateExpressionTree } from "../functions/evaluate_expression_tree";

export class DestinationService {
  private parser: (params: string) => string = (params) => params;

  private isExpressionHasLogicalOperators = (expression: string): boolean => {
    return /&&|<=|>=|!=|==|&|!|\|{1,2}/gi.test(
      expression
    );
  };

  constructor(parser?: (text: string) => any) {
    if (parser) this.parser = parser;
  }

  public parse = ({ destination }: { destination: string }): string => {
    const parsedString = this.parser(destination);
    if (!this.isExpressionHasLogicalOperators(parsedString)) return destination;
    try {
      return evaluateExpressionTree(jsep(parsedString)) || destination;
    } catch {
      return destination;
    }
  };
}
