export enum TypographyVariants {
    body1 = 'body1',
    h3 = 'h3',
    h2 = 'h2',
    h4 = 'h4',
    label = 'label',
    body2 = 'body2',
    title = 'title',
    bigTitle = 'bigTitle',
    button = 'button'
}