// node modules
import { createUseStyles } from "react-jss";

interface propsInterface {
    variant: boolean
}

export const useStyles = createUseStyles({
    block: {
        border: ({variant}: propsInterface) => `1px solid ${variant ? '#CF382F' : '#97C75A'}`,
        background: ({variant}: propsInterface) => variant ? '#FBEBEB' : '#F0F8E6',
        display: 'flex',
        padding: '12px 17px',
        width: '100%',
        alignItems: 'flex-start',
        boxSizing: 'border-box',
        borderRadius: '10px'
    },
    typography: {
        textAlign: 'left',
        letterSpacing: '0.0008em',
        color: '#121620',
        fontWeight: 'normal',
        marginLeft: '10px',
        width: '100%'
    }
})