// node modules
import React, {FC,useState} from 'react';

// local components
import { Slide } from './slide';

// local files
import { SliderProps } from "./slide";
import { useStyles } from "./styles";

export interface SliderPageProps {
    className?: string;
    textColor?: string;
    slideClass?: string;
    background: string,
    elements: SliderProps[],
    onFinished: () => void;
}

export const SliderPage: FC<SliderPageProps> = ({
    className = '',
    elements= [],
    onFinished,
    background,
    textColor,
    slideClass
}) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const handleNextSlide = () => {
        if (activeSlide + 1 === elements.length) {
            if (onFinished) {
                onFinished()
            }
        } else {
            setActiveSlide(activeSlide + 1)
        }
    }
    const { root } = useStyles({height: window.innerHeight, component: typeof elements[activeSlide].value !== 'string'});


    return (
        <div className={`${root} ${className}`} style={{ background }}>
            <Slide
                rootSlideClass={slideClass}
                color={textColor}
                handleNext={handleNextSlide}
                content={elements[activeSlide]}
            />
        </div>
    )
};
