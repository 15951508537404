// node modules
import { createUseStyles } from "react-jss";

// local files
import {Theme} from "../../../theme";

export const useStyles = createUseStyles({
    root: ({ theme }: Theme) => {
        const { selectedButton = () => ({}) } = theme;
        const { root } = selectedButton(theme);

        return { ...root }
    },
    rootSelected: ({ theme }: Theme) => {
        const { selectedButton = () => ({}) } = theme;
        const { rootSelected } = selectedButton(theme);

        return { ...rootSelected }
    },
    "@media (max-width: 365px)": {
        root: {
            "& >p[class^='title'], &>p[class*=' title'], & >p[class^='body'], &>p[class*=' body']": {
                '& >br': {
                    display: 'none',
                },
                '& >span br': {
                    display: 'none',
                }
            },
        }
    },
})
