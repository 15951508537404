// node modules
import React, { FC } from 'react';

// local files
import { useStyles } from "./styles";
import { theming } from "../../../theme";

export interface ProgressBarProps {
    percents: number;
    className?: string
}

export const ProgressBar: FC<ProgressBarProps> = ({ percents, className }) => {
    const { wrapper, childBar, text } = useStyles({ theme: theming.useTheme() });
    const width = `calc(100% * ${percents/100})`;

    return (
        <div className={`${wrapper} ${className}`}>
            <span className={text}>{percents} %</span>
            <div className={childBar} style={{ width }} />
        </div>
    )
};
