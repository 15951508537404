// node modules
import React, {FC, useEffect, useState} from 'react';

// local components
import {SimpleButton} from "../buttons/simple_button";
import {Typography} from "../typography";

// local files
import {uniqIdGenerator} from "../../utils/local_utils";
import {theming} from "../../theme";
import {useStyles} from "./styles";
import {TypographyVariants} from "../../constants";

type item = {
    title: string;
    Content: FC<any>,
}

export interface TabProps {
    list: item[],
    activeTab: number,
    width?: string | number,
    contentClass?: string;
    tabWrapperClass?: string;
    tabClass?: string;
    tabs: Array<string>,
    onChange?: (index: number) => any,
}

export const Tab: FC<TabProps> = ({
    list,
    activeTab = 0,
    width = '100%',
    contentClass = '',
    tabWrapperClass = '',
    tabClass = '',
    tabs,
    onChange,
}) => {
    const { tabWrapper, activeBtn, btn, wrapper } = useStyles({ theme: theming.useTheme() })
    const [currentTab, setCurrentTab] = useState(activeTab);

    useEffect(() => {
        setCurrentTab(activeTab)
    }, [activeTab])

    useEffect(() => {
        if (onChange) onChange(currentTab)
    }, [currentTab])

    if (tabs?.length) return (
        <div className={wrapper} style={{ width }}>
            <div className={`${tabWrapper} ${tabWrapperClass}`}>
                {tabs.map((title, i) => {
                    const className = `${i === currentTab ? activeBtn : ''} ${btn} ${tabClass}`

                    return (
                        <SimpleButton
                            key={uniqIdGenerator()}
                            className={className}
                            width={`calc(100% / ${tabs.length} )`}
                            onClick={() => setCurrentTab(i)}
                            label={
                                <Typography variant={TypographyVariants.label}>
                                    {title}
                                </Typography>
                            }
                        />
                    )})}
            </div>
        </div>
    )

    return (
        <div className={wrapper} style={{ width }}>
            <div className={`${tabWrapper} ${tabWrapperClass}`}>
                {list.map(({title}, i) => {
                    const className = `${i === currentTab ? activeBtn : ''} ${btn} ${tabClass}`

                    return (
                        <SimpleButton
                            key={uniqIdGenerator()}
                            className={className}
                            width={`calc(100% / ${list.length} )`}
                            onClick={() => setCurrentTab(i)}
                            label={
                                <Typography variant={TypographyVariants.label}>
                                    {title}
                                </Typography>
                            }
                        />
                    )})}
            </div>
            <div className={contentClass}>
                {list[currentTab].Content}
            </div>
        </div>
    )
};
