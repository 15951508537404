import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    loaderWrapper: {
        height: 150,
        margin: 0,
        marginBottom: 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loader: {
        width: 50,
        height: 50
    }
});
