// node modules
import React, {FC} from 'react';

// local components
import {SimpleButton} from "../buttons/simple_button";
import {Typography} from "../typography";

// local files
import {theming} from "../../theme";
import checkIcon from '../../assets/check_icon_black.svg';
import closeIcon from '../../assets/close_icon.svg';
import {useStyles} from "./styles";
import {TypographyVariants} from "../../constants";

export interface PriceBlockProps {
    btnLabel: any;
    active: boolean;
    price: string | number;
    term?: string;
    priceText?: string;
    savingPrice: string | number;
    trialTitle: string;
    onClick?: () => void;
    currency?: string;
    className?: string;
    trialIcon?: boolean;
    totalSavingText?: string;
    label?: string;
    translation?: {
        price_block_yes?: string,
        price_block_no?: string
    }
}

export const PriceBlock: FC<PriceBlockProps> = ({
    totalSavingText,
    btnLabel,
    active,
    price,
    term,
    priceText,
    savingPrice,
    trialTitle,
    currency = '$',
    className = '',
    onClick = () => {},
    trialIcon= true,
    label = 'POPULAR',
    translation
}) => {
    const {
        wrapper,
        icon,
        content,
        btn,
        activeBtn,
        activeImg,
        typClass,
        priceWrapper,
        priceWrapperActive,
        wrapperActive,
        activePopUp,
        priceTextStyle,
        activeTotalSavingTitle
    } = useStyles({ theme: theming.useTheme() });

    return (
        <div className={`${wrapper} ${active ? wrapperActive : ''} ${className}`}>
            <div className={content}>
                <Typography className={`${priceWrapper} ${active ? priceWrapperActive : ''}`} variant={TypographyVariants.body1}>
                    {currency}{price}
                    {term && <Typography variant={TypographyVariants.body2}>/{term}</Typography>}
                </Typography>
                {priceText && <Typography className={priceTextStyle} variant={TypographyVariants.body2}> {priceText}</Typography>}
                <Typography className={typClass} variant={TypographyVariants.h4}>{totalSavingText || 'Total savings'}</Typography>
                <Typography className={active ? activeTotalSavingTitle : ''} variant={TypographyVariants.title}>
                    {currency}{savingPrice}
                </Typography>
                <Typography className={typClass} variant={TypographyVariants.h4}>{trialTitle}</Typography>
                {
                    trialIcon
                    ? <img className={`${icon} ${active ? activeImg : ''}`} src={active ? closeIcon : checkIcon} alt=""/>
                    : <Typography className={priceTextStyle} variant={TypographyVariants.body2}>
                            {active ? translation?.price_block_no || 'no' : translation?.price_block_yes || 'yes'}
                        </Typography>
                }
                <SimpleButton onClick={onClick} className={`${btn} ${active ? activeBtn : ''}`} label={btnLabel} />
            </div>
            {active && (
                <Typography className={activePopUp} variant={TypographyVariants.label}>
                    {label}
                </Typography>
            )}
        </div>
    )
}