// node modules
import React, { FC, ReactNode } from 'react';

// local files
import { useStyles } from "./styles";
import { theming } from "../../theme";
import { TypographyVariants } from '../../constants';

export interface TypographyProps {
    variant: TypographyVariants;
    className?: string;
    children?: string | ReactNode,
    style?: any
}

export const Typography: FC<TypographyProps> = ({
    className,
    variant,
    children,
    ...otherProps
}) => {
    const classes: any = useStyles({ theme: theming.useTheme() });
    const variantClassName = classes[variant] ? classes[variant] : classes.body1;
    const typClassName = `${variantClassName} ${className}`

    return <p className={typClassName} {...otherProps}>{children}</p>
};


