export const alignment = {
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    betweenCenter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    rowCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}