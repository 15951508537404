// node modules
import React, {FC,} from 'react';

// local components
import {Typography} from "../typography";

// local files
import successIcon from '../../assets/check_icon_green.svg';
import errorIcon from '../../assets/error_icon.svg';
import {useStyles} from "./styles";
import {TypographyVariants} from "../../constants";

export interface InfoBlockProps {
    variant: 'error' | 'success';
    text: string;
    className?: string
}

export const InfoBlock: FC<InfoBlockProps> = ({
    variant,
    text,
    className = '',
}) => {
    const { block, typography } = useStyles({variant: variant === 'error'});
    const blockClassName = `${block} ${className}`

    return (
        <div className={blockClassName}>
            <img src={variant === 'error' ? errorIcon : successIcon} alt="icon"/>
            <Typography variant={TypographyVariants.h4} className={typography}>
                {text}
            </Typography>
        </div>
    )
}