import React, { useEffect } from 'react';

import { appendScript } from './script_loader';

import { AmazonButtonProps } from "../";
import { useStyles } from "./styles";

type ButtonLoadProps = {
    onError?: () => void,
    onAuth?: () => void,
    sellerId: string
}

const AMAZON_PAY_BUTTON_DIV_ID = 'AmazonPayButton';

const buttonLoad = ({ onError, onAuth, sellerId }: ButtonLoadProps) => () => {
    // @ts-ignore
    window.OffAmazonPayments.Button(AMAZON_PAY_BUTTON_DIV_ID, sellerId, {
        authorization: () => {
            // @ts-ignore
            window.amazon.Login.authorize({ scope: 'profile payments:widget' }, onAuth);
        },
        onError
    });
}

export const AmazonPaymentButton = ({ sellerId, clientId, env, onAuth, onError = () => {} }: AmazonButtonProps) => {
    const { amazonPayButton } = useStyles();

    const handleAmazonLoginReady = () => {
        // @ts-ignore
        window.amazon.Login.setClientId(clientId);
    }

    useEffect(() => {
        //@ts-ignore
        window.onAmazonLoginReady = handleAmazonLoginReady;
    }, [handleAmazonLoginReady]);

    useEffect(() => {
        const isSandbox = env === 'development';
        const loadBtn = buttonLoad({ onError, onAuth, sellerId })

        appendScript(isSandbox, undefined, loadBtn);
    }, []);

    return (
        <div className={amazonPayButton} id={AMAZON_PAY_BUTTON_DIV_ID} />
    )
};
