import { createUseStyles } from "react-jss";

import { alignment } from "../../../theme/alignment";
import {Theme} from "../../../theme";

export const useStyles = createUseStyles({
    inputWrapper: {
        borderRadius: 10,
        border: '1px solid #c7c7c7',
        marginBottom: 12,
        overflow: 'hidden',
        '& input': {
            border: 'none',
            padding: '14px 12px',
            height: 46,
            fontSize: 16,
            width: '100%',
            outline: 'none',
            fontWeight: 400,
            color: '#121620',
            '&:focus': {
                border: 'none'
            }
        },
        '& input::placeholder': {
            color:'#8E8E93 !important'
        }
    },
    inputErrorWrapper: {
        position: 'relative',
        marginBottom: 12
    },
    inputName: {
        marginTop: 16
    },
    cvvInput: {
        marginLeft: 12
    },
    errorText: {
        wordWrap: 'break-word',
        color: '#FF5758',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.0008em',
        textAlign: 'left',
        margin: '4px 0 0 0'
    },
    cvvWrapper: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    zipCodeInput: {
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    form: {
        margin: '0 8px',
        maxWidth: 359,
        maxHeight: '90%',
        overflowY: 'auto',
        position: 'relative',
        background: "white",
        '& *': {
            boxSizing: 'border-box'
        },
        '& iframe': {
            height: '48px !important',
        },
        borderRadius: 16,
        padding: '64px 24px',
        boxSizing: 'border-box'
    },
    button: {
        width: '100%',
        height: 48,
        borderRadius: 10,
        background: ({ theme: { palette } }: Theme) => palette.primary,
        ...alignment.center,
        '&:hover': {
            background: ({ theme: { palette } }: Theme) => palette.primary,
        },
        '& p': {
            fontSize: 14,
            lineHeight: '18px'
        }
    },
    buttonContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            marginRight: 8,
            marginBottom: 3
        }
    },
    cardIconError: {
        position: 'absolute',
        top: 24,
        right: 13,
        transform: 'translate(0, -50%)',
        width: 29
    },
    cardIcon: {
        position: 'absolute',
        top: 25,
        right: 15,
        transform: 'translate(0, -50%)',
        width: 28
    },
    close: {
        position: 'absolute',
        top: 16,
        right: 26,
        cursor: 'pointer'
    },
    errorBlock: {
        marginTop: 16
    }
});
