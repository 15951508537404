import React, { useEffect } from "react";
import { PaymentRequestTokenEvent } from "@stripe/stripe-js";
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import { useStyles } from "./styles";

type AppleGooglePayProps = {
    price: number,
    currency: string,
    isPaymentRequestShowed?: boolean,
    setPaymentRequest: (arg: any) => any,
    setStripeResult: (arg: any) => any,
    setGpayLoaded: (arg: boolean) => void,
    setApayLoaded: (arg: boolean) => void,
    onSubmit: any,
    paymentRequest: any,
}

export const AppleGooglePay = ({
    price,
    currency,
    isPaymentRequestShowed,
    setPaymentRequest,
    setStripeResult,
    onSubmit,
    paymentRequest,
    setGpayLoaded,
    setApayLoaded
}: AppleGooglePayProps) => {
    const stripe = useStripe();
    const { appleGooglePay } = useStyles();

    const submitPayment = (result: Record<string, boolean>) => (e: PaymentRequestTokenEvent) => {
        if (result?.applePay) {
            onSubmit({ event: e, price, type: 'applePay' });
        }

        if (result?.googlePay) {
            onSubmit({ event: e, price, type: 'googlePay' });
        }
    };

    const handleReady = () => {
        setApayLoaded(true);
        setGpayLoaded(true);
    }

    useEffect(() => {
        if(price !== undefined && stripe && currency) {
            const pr = stripe?.paymentRequest({
                country: 'GB',
                currency: currency?.toLowerCase(),
                total: {
                    label: 'Hint',
                    amount: price,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
            pr.canMakePayment().then((result: any) => {
                if (result && (result.googlePay || result.applePay)) {
                    setStripeResult(result);
                    pr.on('token', submitPayment(result));
                    setPaymentRequest(pr);
                } else {
                    handleReady();
                }
            })
        }

    }, [stripe, price, currency]);

    if (!isPaymentRequestShowed) return null;

    return (
        <PaymentRequestButtonElement
            onReady={() => {
                console.log('apple google pay ready')
                handleReady();
            }}
            options={{
                paymentRequest,
                style: {
                    paymentRequestButton: {
                        height: '48px'
                    }
                }
            }}
            className={appleGooglePay}
        />
    )
}