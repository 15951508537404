// node modules
import { createUseStyles } from "react-jss";

// local files
import {
    labelClassName,
    inputSelectCommonStylesProps,
    activeLabelClassName,
    disabled
} from "../../theme/common";
import { alignment } from "../../theme/alignment";
import SFProText from '../../assets/fonts/SF-Pro-Text-Regular.otf';
import {Theme} from "../../theme";

const ADORNMENT_WIDTH = 40;

export const useStyles = createUseStyles({
    '@font-face': {
        fontFamily: "SF Pro Text",
        src: `url('${SFProText}') format("opentype")`
    },
    root: (theming: Theme) => {
        const { theme } = theming;
        const { inputRootClass = () => ({})}  = theme;

        return {
            position: 'relative',
            border: `1px solid ${theme.palette.light_gray}`,
            borderRadius: '.625rem',
            boxSizing: 'border-box',
            display: 'flex',
            paddingRight: 16,
            ...inputRootClass(theme)
        }

    },
    input: {
        ...inputSelectCommonStylesProps,
        width: ({ adornmentEnd, adornmentStart }) => `calc(100% - ${adornmentStart ? ADORNMENT_WIDTH : 0}px - ${adornmentEnd ? ADORNMENT_WIDTH : 0}px)`,
        textAlign: 'left',
        fontFamily: 'SF Pro Text',
        '-webkit-tap-highlight-color': 'transparent'
    },
    inputAdorned: {
        left: '30px !important'
    },
    inputAdornedActive: {
        left: '32px !important'
    },
    unlabeled: {
        padding: '12px 0 12px .75rem'
    },
    labelClass: (theming: Theme) => {
        const { theme } = theming;
        const { inputLabelClass = () => ({})}  = theme;

        return {
            ...labelClassName(theming),
            ...inputLabelClass(theme)
        };
    },
    active: (theming: Theme) => {
        const { theme } = theming;
        const { inputLabelActiveClass = () => ({})}  = theme;

        return {
            ...activeLabelClassName(theming),
            ...inputLabelActiveClass(theme)
        };
    },
    disabledClass: {
        ...disabled
    },
    errorClass: (theming: Theme) => {
        const { theme } = theming;
        const { inputErrorClass = () => ({})}  = theme;

        return {
            borderColor: theme?.palette?.error,
            ...inputErrorClass(theme)
        };
    },
    typeWrapper: {
        ...alignment.center,
        paddingLeft: 11,
        whiteSpace: 'nowrap',
        width: 40
    },
    typeWrapperStart: {
        ...alignment.center,
        paddingRight: 11
    },
    clearIconClass: {
        cursor: 'pointer',
        paddingLeft: 11
    }
});
