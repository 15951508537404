import { createUseStyles } from "react-jss";

import { alignment } from "../../theme/alignment";
import { Theme } from "../../theme";

export const useStyles = createUseStyles({
    wrapper: {
        display: 'none'
    },
    active: {
        display: 'block'
    },
    button: {
        width: '100%',
        height: 48,
        borderRadius: 10,
        background: ({ theme: { palette } }: Theme) => palette.primary,
        ...alignment.center,
        '&:hover': {
            background: ({ theme: { palette } }: Theme) => palette.primary
        },
        '& p': {
            fontSize: 14,
            lineHeight: '18px'
        }
    },
    buttonContent: {
        display: 'flex',
        alignItems: 'center'
    },
    cardIcon: {
        marginRight: 8
    },
    hiddenWindow: {
        display: 'none'
    },
    errorBlock: {
        marginBottom: 14
    }
});
